var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"v2025.15.3"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { initClient, isIgnoredEvent } from '@sortlist-frontend/mlm';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_PUBLIC_SENTRY_DSN;

initClient(SENTRY_DSN || 'https://fac4ef76553f45bca772181d27552c59@o27611.ingest.sentry.io/5191789', {
  // @link https://docs.sentry.io/platforms/javascript/configuration/tree-shaking/
  // @link https://develop.sentry.dev/sdk/performance/
  // To turn it off and avoid bundling the tracing package (30kb), remove the line
  // @see https://github.com/getsentry/sentry-javascript/issues/4722
  tracesSampleRate: ['true', '1'].includes(process.env.NEXTJS_SENTRY_TRACING ?? '') ? 0.005 : 0,
  beforeSend(event) {
    // Additional check for ignored errors - sometimes the error lies deep in the stack
    if (isIgnoredEvent(event)) {
      return null;
    }

    // When we're developing locally
    if (process.env.NODE_ENV !== 'production') {
      // Don't actually send the errors to Sentry
      return null;
    }

    return event;
  },
});
