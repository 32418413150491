export type ExperimentEventBody<ExperimentKey extends string = string, ExperimentVariant = string> = Record<
  ExperimentKey,
  ExperimentVariant
>;

/**
 * See https://github.com/sortlist/sortlist/blob/dev/src/rails/api-public/app/models/tracking_event.rb
 * for the backend allowed fields
 */
type EventType = 'experimentViewed' | 'experimentAssigned';

type TrackingDataBody = {
  attributes: {
    name: EventType;
    sent_at: string;
    ab_tests: {
      gbuuid: string;
      experiments: ExperimentEventBody;
      context: string;
    };
    url: string;
  };
};

export type TrackExperimentValues = {
  eventType: EventType;
  gbuuid: string;
  experiments: ExperimentEventBody;
  // Current url of the page the event is triggered on
  url: string;
  // The base url of where the tracking POST call should be made
  endpointBaseUrl: string;
  context: 'worker' | 'server' | 'client';
};

export const trackExperiment = async (
  values: TrackExperimentValues,
  { onError }: { onError: (e: { fetchError: Error; xhrError?: Error }) => void },
) => {
  const logEndpoint = `${values.endpointBaseUrl}/log`;

  const payload = JSON.stringify({
    data: {
      attributes: {
        name: values.eventType,
        sent_at: new Date().toISOString(),
        ab_tests: {
          gbuuid: values.gbuuid,
          context: values.context,
          experiments: values.experiments,
        },
        url: values.url,
      },
    } satisfies TrackingDataBody,
  });

  try {
    await fetch(logEndpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: payload,
      cache: 'no-store',
    });
  } catch (fetchError) {
    // Only try XHR if we're in a browser environment
    if (typeof XMLHttpRequest !== 'undefined') {
      try {
        await new Promise<void>((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.open('POST', logEndpoint, true);
          xhr.setRequestHeader('Accept', 'application/json');
          xhr.setRequestHeader('Content-Type', 'application/json');

          xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
              resolve();
            } else {
              reject(new Error(`XHR failed with status ${xhr.status}`));
            }
          };

          xhr.onerror = () => {
            reject(new Error('XHR network error'));
          };

          xhr.send(payload);
        });
      } catch (xhrError) {
        console.error('Both fetch and XHR failed', { xhrError, fetchError });
        onError({ xhrError: xhrError as Error, fetchError: fetchError as Error });
      }
    } else {
      onError({ fetchError: fetchError as Error });
      console.warn('XMLHttpRequest is not available in this environment');
    }
  }
};
